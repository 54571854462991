import { useEffect, useMemo, useRef } from 'react'
import gsap from 'gsap'

const usePageThemeColorTransition = (pageColor, elementRef, duration = 0.5) => {
  const currentColor = useRef({ ...pageColor })
  useEffect(() => {
    if (currentColor && pageColor) {
      const root = elementRef?.current || document.getElementById('main') || document.documentElement
      gsap.to(currentColor.current, {
        foreground: pageColor.foreground,
        background: pageColor.background,
        transparentBackground: pageColor.transparentBackground,
        border: pageColor.border,
        duration,
        ease: 'sine.inOut',
        onUpdate: () => {
          root.style.setProperty('--foreground', currentColor.current.foreground)
          root.style.setProperty('--background', currentColor.current.background)
          root.style.setProperty('--transparentBackground', currentColor.current.transparentBackground)
          root.style.setProperty('--border', currentColor.current.border)
        }
      })
    }
  }, [pageColor, elementRef, duration])
}

export const getPageColorStyles = (pageColor) => {
  return useMemo(() => ({
    '--foreground': pageColor.foreground,
    '--background': pageColor.background,
    '--transparentBackground': pageColor.transparentBackground,
    '--border': pageColor.border
  }), [pageColor])
}

export default usePageThemeColorTransition
