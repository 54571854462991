import { createProxyState } from './stateHelpers'
import algoliasearch from 'algoliasearch/lite'
import layoutSlice from './layoutSlice'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { derive } from 'valtio/utils'
import * as gtag from '../lib/gtag'

const LIMIT = 10

export const searchSlice = {
  name: 'search',
  state: {},
  create: ({ pageData }) => {
    searchSlice.state = createProxyState({
      query: '',
      loading: false,
      debouncedQuery: '',
      activeTabIndex: 0,
      results: {
        product: {
          filter: 'pageType:"product" AND active:true',
          result: null,
          page: 0,
          loading: false
        },
        page: {
          filter: 'pageType:"page"',
          result: null,
          page: 0,
          loading: false
        },
        journal: {
          filter: 'pageType:"post"',
          result: null,
          page: 0,
          loading: false
        }
      }
    })
    return searchSlice.state
  },
  derive: () => {
    derive({
      summary: (get) => {
        const page = get(searchSlice.state.results.page)
        const product = get(searchSlice.state.results.product)
        const journal = get(searchSlice.state.results.journal)
        return {
          product: product.result && product.result.hits.slice(0, 3),
          journal: journal.result && journal.result.hits.slice(0, 3),
          page: page.result && page.result.hits.slice(0, 3)
        }
      },
      empty: (get) => {
        const page = get(searchSlice.state.results.page)
        const product = get(searchSlice.state.results.product)
        const journal = get(searchSlice.state.results.journal)
        return !get(product, ['result', 'hits', 'length']) &&
          !get(journal, ['result', 'hits', 'length']) &&
          !get(page, ['result', 'hits', 'length'])
      }
    }, { proxy: searchSlice.state })
  }
}

export const getSearchQuery = (state) => state.search.query
export const isSearchLoading = (state) => state.search.loading
export const getSearchResults = (state) => state.search.results
export const getProductSearchResults = (state) => state.search.results.product.result
export const getPageSearchResults = (state) => state.search.results.page.result
export const getJournalSearchResults = (state) => state.search.results.journal.result
export const getSearchSummary = (state) => state.search.summary
export const getSearchQueryResolved = (state) => get(state.search, ['results', 'product', 'result', 'query'])
export const getActiveTabIndex = (state) => state.search.activeTabIndex

export const setActiveTabIndex = (index) => { searchSlice.state.activeTabIndex = index }

let activeSearchId = 0

const executeSearch = async () => {
  activeSearchId++
  const searchId = activeSearchId

  const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID, process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY)
  const locale = layoutSlice.state.site.locale
  const query = searchSlice.state.query

  if (query) {
    forEach(searchSlice.state.results, (result) => {
      result.loading = true
    })

    const results = await searchClient.search(
      map(searchSlice.state.results, ({ filter }) => ({
        indexName: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}-${locale}`,
        query,
        params: {
          optionalWords: query,
          filters: filter,
          hitsPerPage: LIMIT,
          page: 0
        }
      }))
    )
    // If there is a newer search query, then discard these results

    let i = 0
    forEach(searchSlice.state.results, (r) => {
      if (searchId === activeSearchId) {
        r.result = results.results[i]
        r.page = 0
      }
      r.loading = false
      i++
    })
  } else {
    // Clears the search results if there is no query
    forEach(searchSlice.state.results, (result) => {
      result.result = null
    })
  }
  searchSlice.state.loading = false
  gtag.event({
    action: 'search',
    label: 'Search',
    value: searchSlice.state.query
  })
}

const executeSearchDebounced = debounce(executeSearch, 1000)

export const setSearchQuery = async (query, debounced = true) => {
  searchSlice.state.query = query
  // If the query is empty we can remove the results without waiting for the debounce
  if (!query) {
    forEach(searchSlice.state.results, (result) => {
      result.result = null
    })
  } else {
    searchSlice.state.loading = true
    if (debounced) {
      executeSearchDebounced()
    } else {
      executeSearch()
    }
  }
}

export const LoadNextPage = async (source) => {
  const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID, process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY)
  const locale = layoutSlice.state.site.locale

  const query = searchSlice.state.query

  const searchState = searchSlice.state.results[source]
  searchState.loading = true

  const { results } = await searchClient.search([{
    indexName: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}-${locale}`,
    query,
    params: {
      filters: searchState.filter,
      hitsPerPage: LIMIT,
      page: searchState.page + 1
    }
  }])

  searchState.page = searchState.page + 1
  searchState.result = {
    ...results[0],
    hits: [
      ...searchState.result.hits,
      ...results[0].hits
    ]
  }
  searchState.loading = false
}

export default searchSlice
