import { derive } from 'valtio/utils'
import FontFaceObserver from 'fontfaceobserver'
import { isBrowser } from '../lib/helpers'
import { faces } from '../styles/fonts'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import { createProxyState } from './stateHelpers'

export const preloadSlice = {
  name: 'preload',
  state: {},
  create: (initialState) => {
    preloadSlice.state = createProxyState({
      fontsLoading: map(faces, ({ fontFamily, fontWeight }) => ({
        fontFamily: fontFamily.replace(/"/g, ''),
        fontWeight,
        loading: true
      }))
    })
    if (isBrowser) {
      preloadFonts()
    }
    return preloadSlice.state
  },
  derive: () => {
    derive({
      fontsLoaded: (get) => {
        const state = get(preloadSlice.state)
        return !find(state.fontsLoading, x => x.loading)
      }
    }, { proxy: preloadSlice.state })
  }
}

const preloadFonts = (store) => {
  const fonts = preloadSlice.state.fontsLoading
  forEach(fonts, ({ fontFamily, fontWeight }) => {
    const loader = new FontFaceObserver(fontFamily, { fontWeight })
    const onLoaded = () => {
      const font = preloadSlice.state.fontsLoading.find(x => x.fontFamily === fontFamily && x.fontWeight === fontWeight)
      font.loading = false
    }
    loader.load().then(onLoaded).catch(onLoaded)
  })
}

export const isFontsLoaded = (state) => state.preload.fontsLoaded

export default preloadSlice
