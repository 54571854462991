import { createProxyState } from './stateHelpers'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import isObject from 'lodash/isObject'

export function getPriceWithTax (price, taxInclusive) {
  return isObject(price) ? price[taxInclusive ? 'tax_inclusive' : 'tax_exclusive'] : price
}

export function getLineItemPrice (price, taxInclusive) {
  return isObject(price) ? price[taxInclusive ? 'sale_price' : 'list_price'] : price
}

export function getCartTotalPrice (price, taxInclusive) {
  return isObject(price) ? price[taxInclusive ? 'cartAmount' : 'baseAmount'] : price
}

export function getMinMaxPrices (prices, taxInclusive) {
  const min = Math.min(prices?.map(p => getPriceWithTax(p, taxInclusive))) || 0
  const max = Math.max(prices?.map(p => getPriceWithTax(p, taxInclusive))) || 0
  return { min, max }
}

export const pricesSlice = {
  name: 'prices',
  state: {},
  create: ({ pageData }) => {
    pricesSlice.state = createProxyState({
      prices: {},
      currencyCode: null,
      locale: null,
      countryCode: null
    })
    return pricesSlice.state
  }
}

const getPriceId = (productId, variantId) => variantId ? `${productId}-${variantId}` : productId

export const hasPrice = (state, productId, variantId) => !!get(state.prices.prices, [getPriceId(productId, variantId)])
export const getPrice = (state, productId, variantId) => get(state.prices.prices, [getPriceId(productId, variantId), 'price'])
export const getSalePrice = (state, productId, variantId) => get(state.prices.prices, [getPriceId(productId, variantId), 'sale_price'])
export const getCurrencyCode = (state) => state.prices.currencyCode
export const getCountryCode = (state) => state.prices.countryCode
export const getCurrencyLocale = (state) => state.prices.locale
export const isTaxInclusive = (state) => state.prices.taxInclusive

export function loadPrices (data) {
  const { prices, currency, locale, countryCode, taxInclusive } = data
  forEach(prices, price => {
    pricesSlice.state.prices[getPriceId(price.product_id, price.variant_id)] = price
  })
  pricesSlice.state.currencyCode = currency
  pricesSlice.state.locale = locale
  pricesSlice.state.countryCode = countryCode
  pricesSlice.state.taxInclusive = taxInclusive
}

export default pricesSlice
