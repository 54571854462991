export const unquotedBodyFace = 'Sohne'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 400

export const unquotedHeadingFace = 'Iskry'
export const headingFace = `"${unquotedHeadingFace}"`
export const heading = `${headingFace}, "Lucida Sans", Tahoma, sans-serif`
export const headingFontWeight = 400

export const preload = [
  { src: '/fonts/Sohne-Leicht.woff2' },
  { src: '/fonts/Iskry-Regular.woff2' },
  { src: '/fonts/Iskry-Bold.woff2' }
]

export const faces = [{
  fontFamily: bodyFace,
  src: 'url(\'/fonts/Sohne-Leicht.woff2\') format(\'woff2\'), url(\'/fonts/Sohne-Leicht.woff\') format(\'woff\')',
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: headingFace,
  src: 'url(\'/fonts/Iskry-Regular.woff2\') format(\'woff2\'), url(\'/fonts/Iskry-Regular.woff\') format(\'woff\')',
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: headingFace,
  src: 'url(\'/fonts/Iskry-Bold.woff2\') format(\'woff2\'), url(\'/fonts/Iskry-Bold.woff\') format(\'woff\')',
  fontWeight: 'bold',
  fontStyle: 'normal'
}]
