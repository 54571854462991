import { createProxyState } from './stateHelpers'

export const sampleListingSlice = {
  name: 'sampleListing',
  state: {},
  create: () => {
    sampleListingSlice.state = createProxyState({
      index: 0,
      filter: {}
    })
    return sampleListingSlice.state
  }
}

export default sampleListingSlice
