export const configureLazySizes = () => {
  window.lazySizesConfig = window.lazySizesConfig || {}
  window.lazySizesConfig.init = false
  window.lazySizesConfig.expand = 500
  window.lazySizesConfig.hFac = 2
}

const initLazySizes = () => {
  if (!(typeof window === 'undefined')) {
    configureLazySizes()
    require('lazysizes/plugins/attrchange/ls.attrchange')
    const lazysizes = require('lazysizes')
    lazysizes.init()
  }
}

export default initLazySizes
