import { subscribeKey } from 'valtio/utils'
import { isBrowser } from '../lib/helpers'
import routeSlice from './routeSlice'
import { createProxyState } from './stateHelpers'
import layoutSlice from './layoutSlice'
import Cookies from 'js-cookie'
import queryString from 'query-string'
import defer from 'lodash/defer'

const COOKIE_KEY = 'armadillo_sales_popup_dismissed'

const checkPopup = () => {
  if (isBrowser) {
    const { active } = layoutSlice.state?.site?.salesPopup || {}
    if (active) {
      const dismissed = Cookies.get(COOKIE_KEY)
      if (!dismissed) {
        setOpen(true)
      }
    }
  }
}

export const salesPopupSlice = {
  name: 'salesPopup',
  state: {},
  create: ({ pageData }) => {
    salesPopupSlice.state = createProxyState({
      open: false
    })
    checkPopup()
    return salesPopupSlice.state
  },
  subscribe: () => {
    subscribeKey(routeSlice.state, 'pathname', (value) => {
      salesPopupSlice.state.open = false
    })
    subscribeKey(layoutSlice.state, 'site', (value) => {
      checkPopup()
    })
  }
}

export const isOpen = (state) => state.salesPopup.open
export const setOpen = (value) => { salesPopupSlice.state.open = value }

export const openSalesPopup = () => { salesPopupSlice.state.open = true }

export const dismiss = () => {
  salesPopupSlice.state.open = false
  // This will stop the popup from showing for another 7 days
  Cookies.set(COOKIE_KEY, true, { expires: 7 })
}

const checkPopupOpenFromQueryString = () => {
  if (isBrowser) {
    const qs = queryString.parse(window.location.search)
    if (qs.popupOpen !== undefined) {
      defer(() => setOpen(true))
    }
  }
}

checkPopupOpenFromQueryString()

export default salesPopupSlice
