import { createProxyState } from './stateHelpers'

export const subscriptionSlice = {
  name: 'subscription',
  state: {},
  create: (initialState) => {
    subscriptionSlice.state = createProxyState({
      loading: false,
      subscribed: false,
      error: null
    })
    return subscriptionSlice.state
  }
}

export const isLoading = (state) => state.subscription.loading
export const isSubscribed = (state) => state.subscription.subscribed
export const isError = (state) => state.subscription.error

export const subscribeToNewsletter = async (data) => {
  subscriptionSlice.state.loading = true
  subscriptionSlice.state.error = null
  const response = await window.fetch('/api/newsletter', {
    method: 'POST',
    body: JSON.stringify(data)
  })
  if (response.ok) {
    subscriptionSlice.state.loading = false
    subscriptionSlice.state.subscribed = true
  } else {
    subscriptionSlice.state.loading = false
    subscriptionSlice.state.error = 'Oops, something went wrong'
  }
  return response
}

export default subscriptionSlice
