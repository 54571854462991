import filter from 'lodash/filter'
import { createProxyState } from './stateHelpers'
import { subscribeKey } from 'valtio/utils'
import searchSlice from './searchSlice'
import { isBrowser } from '../lib/helpers'
import { sessionStore } from '../helpers/storageFactory'

const LIMIT = 6
const KEY = 'armadillo-recent-search-terms-v1'

export const recentSearchSlice = {
  name: 'recentSearch',
  state: {},
  create: ({ pageData }) => {
    let terms = []
    if (isBrowser) {
      const sessionTerms = sessionStore.getItem(KEY)
      if (sessionTerms) {
        terms = JSON.parse(sessionTerms)
      }
    }
    recentSearchSlice.state = createProxyState({
      terms
    })
    return recentSearchSlice.state
  },
  subscribe: () => {
    subscribeKey(searchSlice.state, 'loading', (loading) => {
      if (loading) return
      const query = searchSlice.state.query
      if (query && query.length >= 3) {
        const otherTerms = filter(recentSearchSlice.state.terms, term => !query.startsWith(term) && !term.startsWith(query))
        const terms = [
          query,
          ...otherTerms
        ].slice(0, LIMIT)
        recentSearchSlice.state.terms = terms
        sessionStore.setItem(KEY, JSON.stringify(terms))
      }
    })
  }
}

export const getRecentSearchTerms = (state) => state.recentSearch.terms

export default recentSearchSlice
