// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// export const pageview = (gaMeasurementId, url, title) => {
//   if (window.gtag) {
//     window.gtag('event', 'page_view', {
//       page_path: url,
//       page_title: title || document.title,
//       page_location: window.location.href,
//       send_to: gaMeasurementId
//     })
//   }
// }

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// export const event = ({ action, category, label, value }) => {
//   if (window.gtag) {
//     window.gtag('event', action, {
//       event_category: category,
//       event_label: label,
//       value
//     })
//   }
// }

const DEBUG_MODE = false

export const ITEM_LIST_SOURCES = {
  searchResults: { name: 'Search Results', id: 'search-results' },
  relatedProducts: { name: 'Related Products', id: 'related-products' },
  wishlist: { name: 'Wishlist', id: 'wishlist' },
  featuredCollection: { name: 'Featured Collection', id: 'featured-collection' }
}

export const pageview = (gaMeasurementId, url, title) => {
  if (!window.dataLayer) window.dataLayer = []
  window.dataLayer?.push({
    event: 'page_view',
    page_path: url,
    page_title: title || document.title,
    page_location: window.location.href
  })
}

export const event = ({ action, category, label, value }) => {
  if (!window.dataLayer) window.dataLayer = []
  window.dataLayer?.push({
    event: action,
    event_category: category,
    event_label: label,
    value
  })
}

// export const userIdAllPages = ({ userId }) => {
//   if (!window.dataLayer) window.dataLayer = []
//   window.dataLayer?.push({
//     event: 'user_data',
//     user_type: userId ? 'logged in' : 'guest', // if user logged in change to "logged in" or similar
//     user_id: userId // populate with userID if user is logged in
//   })
// }

// export const login = ({ userId }) => {
//   if (!window.dataLayer) window.dataLayer = []
//   window.dataLayer?.push({
//     event: 'login',
//     user_type: 'logged in',
//     user_id: userId
//   })
// }

// export const signUp = ({ userId }) => {
//   if (!window.dataLayer) window.dataLayer = []
//   window.dataLayer?.push({
//     event: 'sign_up',
//     user_type: 'logged in',
//     user_id: userId
//   })
// }

// export const logout = () => {
//   if (!window.dataLayer) window.dataLayer = []
//   window.dataLayer?.push({
//     event: 'logout',
//     user_type: 'logged out',
//     user_id: ''
//   })
// }

export const trackProductImpression = (items, list, currencyCode) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'productImpression', // name of an event. In this case, please use productImpression
    ecommerce: { // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
      currencyCode: currencyCode, // 'AUD', // the currency which is currently set by a visitor
      impressions: items.map((item, index) => transformItemToDatalayerProduct(item, index, list)),
      items: items.map((item, index) => transformItemToDatalayerItem(item, index, list))
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log(`Tracking product impression from ${list.name}:`, datalayerObject)
  }
}
export const trackProductClick = (item, list, index) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: {
          list: list // name of the list from which the product was clicked
        },
        products: [transformItemToDatalayerProduct(item, index, list)]
      },
      items: [transformItemToDatalayerItem(item, index, list)]
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log('Tracking product click:', datalayerObject)
  }
}
export const trackProductView = (items) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'productView',
    ecommerce: {
      detail: {
        products: items.map((item) => transformItemToDatalayerProduct(item)),
        items: items.map((item) => transformItemToDatalayerItem(item))
      }
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log('Tracking product view:', datalayerObject)
  }
}
export const trackProductAddToCart = (items, currencyCode) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'addToCart',
    ecommerce: {
      currencyCode: currencyCode, // 'AUD' || 'USD' || NZD,
      add: {
        products: items.map((item) => transformItemToDatalayerProduct(item)),
        items: items.map((item) => transformItemToDatalayerItem(item))
      }
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log('Tracking add to cart:', datalayerObject)
  }
}
export const trackProductRemoveFromCart = (items, currencyCode) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: currencyCode, // 'AUD',
      remove: {
        products: items.map((item) => transformItemToDatalayerProduct(item)),
        items: items.map((item) => transformItemToDatalayerItem(item))
      }
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log('Tracking remove from cart:', datalayerObject)
  }
}

export const trackViewCart = (items) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'view_cart',
    ecommerce: {
      items: items.map((item, index) => transformItemToDatalayerItem(item, index))
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log('Tracking view cart:', datalayerObject)
  }
}

export const trackAddToWishlist = (items) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'add_to_wishlist',
    ecommerce: {
      items: items.map((item) => transformItemToDatalayerItem(item))
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log('Tracking add to wishlist:', datalayerObject)
  }
}

export const trackRemoveFromWishlist = (items) => {
  window.dataLayer = window.dataLayer || []
  const datalayerObject = {
    event: 'remove_from_wishlist',
    ecommerce: {
      items: items.map((item) => transformItemToDatalayerItem(item))
    }
  }
  window.dataLayer.push(datalayerObject)
  if (DEBUG_MODE) {
    console.log('Tracking remove from wishlist:', datalayerObject)
  }
}

const transformItemToDatalayerProduct = (item, index, list) => {
  return {
    name: item.name, // 'Product 1', name of a product that is displayed. Always include it.
    id: item.id, // 'product1', id of a product. Always include it.
    price: item.price && String(item.price), // '11.99', price of a product
    brand: 'Armadillo', // 'brand A', brand/vendor of a product
    category: item.category, // 'Apparel', category of a product
    variant: item.variant, // 'Blue', product variant. If there are no variants, exclude this key from the dataLayer.push
    list: list?.name, // 'main', name of the product list. In this case it will just be the PLP
    position: index // 1 number of a position (in which a product was visible at that time)
  }
}

const transformItemToDatalayerItem = (item, index, list) => {
  return {
    item_name: item.name, // 'Product 1', insert an actual product name
    item_id: item.id, // 'product1', insert an actual product ID
    price: item.price && String(item.price), // '11.99', insert an actual product price. Number or a string. Don't include currency code
    item_brand: 'Armadillo', // 'brand A', insert an actual product price
    item_category: item.category, // 'Apparel', insert an actual product top-level category
    // item_category2: item.category2, // 'T-shirt', if it is possible to drill down the categories (e.g. Apparel, then T-shirt, then Men), use item_category2, item_category3, etc. Can use from item_category up to item_category5
    variant: item.variant, // 'Blue', insert an actual product variant
    item_list_name: list?.name, // 'Search results', insert the name of the list where the product is currently displayed
    item_list_id: list?.id, // 'search_results', insert the list id where the product is currently displayed
    index: index, // 1, insert product's position in that list
    quantity: item.quantity ? String(item.quantity) : '1' // quantity // '1' // product quantity. In case of view_item_list, it will usually be equal to 1
  }
}
