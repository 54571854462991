import { createProxyState } from './stateHelpers'
import { isBrowser } from '../lib/helpers'
import { localStore } from '../helpers/storageFactory'
import locales from '../../locales'
import get from 'lodash/get'
import delay from 'lodash/delay'
import { ref } from 'valtio'

export const SELECTED_UNITS_KEY = 'armadillo-selected-unit-system'
const IMPERIAL_SYSTEM = 'imperial'
const METRIC_SYSTEM = 'metric'

export const unitSystemSlice = {
  name: 'unitSystem',
  state: {},
  create: ({ pageData }) => {
    const settings = ref(get(pageData, ['settings'], null))
    unitSystemSlice.state = createProxyState({
      selectedUnitSystem: settings.locale === locales.countryCodes.us.locale ? IMPERIAL_SYSTEM : METRIC_SYSTEM
    })

    // We need to do this on the next tick so that is does not give a hydration issue
    delay(() => {
      if (isBrowser) {
        loadUnitSystem()
      }
    })
    return unitSystemSlice.state
  }
}

export const getUnitSystem = (state) => state.unitSystem.selectedUnitSystem
export const setUnitSystemToImperial = () => {
  localStore.setItem(SELECTED_UNITS_KEY, IMPERIAL_SYSTEM)
  unitSystemSlice.state.selectedUnitSystem = IMPERIAL_SYSTEM
}
export const setUnitSystemToMetric = () => {
  localStore.setItem(SELECTED_UNITS_KEY, METRIC_SYSTEM)
  unitSystemSlice.state.selectedUnitSystem = METRIC_SYSTEM
}
export const getIsImperialUnitSystem = (state) => state.unitSystem.selectedUnitSystem === IMPERIAL_SYSTEM

export const loadUnitSystem = () => {
  const storedUnitSystem = localStore.getItem(SELECTED_UNITS_KEY)
  if (storedUnitSystem) {
    unitSystemSlice.state.selectedUnitSystem = storedUnitSystem
  }
}

export default unitSystemSlice
