const locales = {
  defaultCountryCode: 'au',
  default: {
    locale: 'en-AU',
    currency: 'AUD',
    name: 'default',
    taxInclusive: true
  },
  routes: {
    us: 'en-US',
    au: 'en-AU'
  },
  countryCodes: {
    au: {
      locale: 'en-AU',
      currency: 'AUD',
      name: 'Australia',
      taxInclusive: true
    },
    nz: {
      locale: 'en-AU',
      currency: 'NZD',
      name: 'New Zealand',
      taxInclusive: true
    },
    us: {
      locale: 'en-US',
      currency: 'USD',
      name: 'United States',
      units: 'imperial'
    },
    ae: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'United Arab Emirates',
      taxInclusive: true
    },
    hk: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Hong Kong',
      taxInclusive: true
    },
    il: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Israel',
      taxInclusive: true
    },
    my: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Malaysia',
      taxInclusive: true
    },
    sg: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Singapore',
      taxInclusive: true
    },
    jp: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Japan',
      taxInclusive: true
    },
    th: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Thailand',
      taxInclusive: true
    },
    ri: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Indonesia',
      taxInclusive: true
    },
    vd: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Viet Nam',
      taxInclusive: true
    },
    lk: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'Sri Lanka',
      taxInclusive: true
    },
    kr: {
      locale: 'en-AU',
      currency: 'USD',
      name: 'South Korea',
      taxInclusive: true
    },
    ca: { locale: 'en-US', currency: 'USD', name: 'Canada' },
    ai: { locale: 'en-US', currency: 'USD', name: 'Anguilla' },
    ag: { locale: 'en-US', currency: 'USD', name: 'Antigua and Barbuda' },
    ar: { locale: 'en-US', currency: 'USD', name: 'Argentina' },
    aw: { locale: 'en-US', currency: 'USD', name: 'Aruba' },
    bs: { locale: 'en-US', currency: 'USD', name: 'Bahamas' },
    bb: { locale: 'en-US', currency: 'USD', name: 'Barbados' },
    bz: { locale: 'en-US', currency: 'USD', name: 'Belize' },
    bm: { locale: 'en-US', currency: 'USD', name: 'Bermuda' },
    bo: { locale: 'en-US', currency: 'USD', name: 'Bolivia' },
    br: { locale: 'en-US', currency: 'USD', name: 'Brazil' },
    ky: { locale: 'en-US', currency: 'USD', name: 'Cayman Islands' },
    cl: { locale: 'en-US', currency: 'USD', name: 'Chile' },
    co: { locale: 'en-US', currency: 'USD', name: 'Colombia' },
    cr: { locale: 'en-US', currency: 'USD', name: 'Costa Rica' },
    cu: { locale: 'en-US', currency: 'USD', name: 'Cuba' },
    dm: { locale: 'en-US', currency: 'USD', name: 'Dominica' },
    do: { locale: 'en-US', currency: 'USD', name: 'Dominican Republic' },
    ec: { locale: 'en-US', currency: 'USD', name: 'Ecuador' },
    sv: { locale: 'en-US', currency: 'USD', name: 'El Salvador' },
    gf: { locale: 'en-US', currency: 'USD', name: 'French Guiana' },
    gd: { locale: 'en-US', currency: 'USD', name: 'Grenada' },
    gp: { locale: 'en-US', currency: 'USD', name: 'Guadeloupe' },
    gt: { locale: 'en-US', currency: 'USD', name: 'Guatemala' },
    gy: { locale: 'en-US', currency: 'USD', name: 'Guyana' },
    ht: { locale: 'en-US', currency: 'USD', name: 'Haiti' },
    hn: { locale: 'en-US', currency: 'USD', name: 'Honduras' },
    jm: { locale: 'en-US', currency: 'USD', name: 'Jamaica' },
    mq: { locale: 'en-US', currency: 'USD', name: 'Martinique' },
    mx: { locale: 'en-US', currency: 'USD', name: 'Mexico' },
    ni: { locale: 'en-US', currency: 'USD', name: 'Nicaragua' },
    pa: { locale: 'en-US', currency: 'USD', name: 'Panama' },
    py: { locale: 'en-US', currency: 'USD', name: 'Paraguay' },
    pe: { locale: 'en-US', currency: 'USD', name: 'Peru' },
    pr: { locale: 'en-US', currency: 'USD', name: 'Puerto Rico' },
    lc: { locale: 'en-US', currency: 'USD', name: 'Saint Lucia' },
    sr: { locale: 'en-US', currency: 'USD', name: 'Suriname' },
    tt: { locale: 'en-US', currency: 'USD', name: 'Trinidad and Tobago' },
    uy: { locale: 'en-US', currency: 'USD', name: 'Uruguay' },
    ve: { locale: 'en-US', currency: 'USD', name: 'Venezuela' },
    al: { locale: 'en-US', currency: 'USD', name: 'Albania' },
    at: { locale: 'en-US', currency: 'USD', name: 'Austria' },
    by: { locale: 'en-US', currency: 'USD', name: 'Belarus' },
    be: { locale: 'en-US', currency: 'USD', name: 'Belgium' },
    bg: { locale: 'en-US', currency: 'USD', name: 'Bulgaria' },
    hr: { locale: 'en-US', currency: 'USD', name: 'Croatia' },
    cz: { locale: 'en-US', currency: 'USD', name: 'Czech Republic' },
    dk: { locale: 'en-US', currency: 'USD', name: 'Denmark' },
    ee: { locale: 'en-US', currency: 'USD', name: 'Estonia' },
    fi: { locale: 'en-US', currency: 'USD', name: 'Finland' },
    fr: { locale: 'en-US', currency: 'USD', name: 'France' },
    de: { locale: 'en-US', currency: 'USD', name: 'Germany' },
    gi: { locale: 'en-US', currency: 'USD', name: 'Gibraltar' },
    gr: { locale: 'en-US', currency: 'USD', name: 'Greece' },
    gl: { locale: 'en-US', currency: 'USD', name: 'Greenland' },
    hu: { locale: 'en-US', currency: 'USD', name: 'Hungary' },
    is: { locale: 'en-US', currency: 'USD', name: 'Iceland' },
    ie: { locale: 'en-US', currency: 'USD', name: 'Ireland' },
    im: { locale: 'en-US', currency: 'USD', name: 'Isle of Man' },
    it: { locale: 'en-US', currency: 'USD', name: 'Italy' },
    lv: { locale: 'en-US', currency: 'USD', name: 'Latvia' },
    li: { locale: 'en-US', currency: 'USD', name: 'Liechtenstein' },
    lt: { locale: 'en-US', currency: 'USD', name: 'Lithuania' },
    lu: { locale: 'en-US', currency: 'USD', name: 'Luxembourg' },
    mt: { locale: 'en-US', currency: 'USD', name: 'Malta' },
    mc: { locale: 'en-US', currency: 'USD', name: 'Monaco' },
    ms: { locale: 'en-US', currency: 'USD', name: 'Montserrat' },
    nl: { locale: 'en-US', currency: 'USD', name: 'Netherlands' },
    no: { locale: 'en-US', currency: 'USD', name: 'Norway' },
    pl: { locale: 'en-US', currency: 'USD', name: 'Poland' },
    pt: { locale: 'en-US', currency: 'USD', name: 'Portugal' },
    ro: { locale: 'en-US', currency: 'USD', name: 'Romania' },
    sm: { locale: 'en-US', currency: 'USD', name: 'San Marino' },
    sk: { locale: 'en-US', currency: 'USD', name: 'Slovakia' },
    si: { locale: 'en-US', currency: 'USD', name: 'Slovenia' },
    es: { locale: 'en-US', currency: 'USD', name: 'Spain' },
    se: { locale: 'en-US', currency: 'USD', name: 'Sweden' },
    ch: { locale: 'en-US', currency: 'USD', name: 'Switzerland' },
    ua: { locale: 'en-US', currency: 'USD', name: 'Ukraine' },

    io: { locale: 'en-US', currency: 'USD', name: 'British Indian Ocean Territory' },
    fk: { locale: 'en-US', currency: 'USD', name: 'Falkland Islands (Malvinas)' },
    pm: { locale: 'en-US', currency: 'USD', name: 'Saint Pierre and Miquelon' },
    kn: { locale: 'en-US', currency: 'USD', name: 'Saint Kitts and Nevis' },
    vc: { locale: 'en-US', currency: 'USD', name: 'Saint Vincent and the Grenadines' },
    tc: { locale: 'en-US', currency: 'USD', name: 'Turks and Caicos Islands' },
    um: { locale: 'en-US', currency: 'USD', name: 'United States Minor Outlying Islands' },
    gb: { locale: 'en-US', currency: 'USD', name: 'United Kingdom' },
    ad: { locale: 'en-US', currency: 'USD', name: 'Andorra' },
    ba: { locale: 'en-US', currency: 'USD', name: 'Bosnia and Herzegovina' },
    fo: { locale: 'en-US', currency: 'USD', name: 'Faroe Islands' },
    va: { locale: 'en-US', currency: 'USD', name: 'Holy See (Vatican City State)' },
    md: { locale: 'en-US', currency: 'USD', name: 'Moldova, Republic of' },
    cs: { locale: 'en-US', currency: 'USD', name: 'Serbia and Montenegro' },
    ru: { locale: 'en-US', currency: 'USD', name: 'Russian Federation' },
    mk: { locale: 'en-US', currency: 'USD', name: 'Macedonia, The Former Yugoslav Republic of,' },
    cw: { locale: 'en-US', currency: 'USD', name: 'Curaçao' },
    sx: { locale: 'en-US', currency: 'USD', name: 'Sint Maarten' }
  }
}

function objectFlip (obj) {
  const ret = {}
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key
  })
  return ret
}

const routesByLocale = objectFlip(locales.routes)

export const getRouteByLocale = (locale) => {
  if (locale === locales.default.locale) {
    return null
  }
  return routesByLocale[locale]
}

export default locales
