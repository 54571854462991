import Script from 'next/script'
import { getKlaviyoId } from '../store/layoutSlice'
import useSnapshot from '../store/useSnapshot'

export default function Klaviyo () {
  const snap = useSnapshot()
  const klaviyoId = getKlaviyoId(snap)

  return (
    <>
      <Script id='klaviyo' strategy='afterInteractive' aync='' src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoId}`} />
    </>
  )
}
