import { subscribeKey, derive } from 'valtio/utils'
import { isBrowser } from '../lib/helpers'
import { current, keys } from '../styles/breakpoints'
import routeSlice from './routeSlice'
import { createProxyState } from './stateHelpers'
import get from 'lodash/get'
import { ref } from 'valtio'

export const layoutSlice = {
  name: 'layout',
  state: {},
  create: ({ pageData }) => {
    layoutSlice.state = createProxyState({
      menuOpen: false,
      preloadMenu: true,
      cartOpen: false,
      underlayOpen: false,
      searchOpen: false,
      wishListOpen: false,
      shopFiltersDialogOpen: false,
      samplesDialogOpen: false,
      samplesProduct: null,
      page: ref(pageData),
      site: ref(get(pageData, ['settings'], null)),
      dialog: null,
      transition: {},
      breakpoint: current(),
      homepageAnimatedIn: false,
      productGalleryOpen: false,
      galleryIndex: 0,
      chatOpen: false,
      isSalesPopupEnabled: false,
      isMenuLoaded: false,
      topBannerHeight: 0,
      colorPickerDialogOpen: false,
      selectedCustomColor: false
    })
    return layoutSlice.state
  },
  derive: () => {
    derive({
      isMobile: (get) => {
        const bp = get(layoutSlice.state).breakpoint
        return !(keys.indexOf(bp) >= keys.indexOf('md'))
      }
    }, {
      proxy: layoutSlice.state
    })
  },
  subscribe: () => {
    subscribeKey(routeSlice.state, 'pathname', (value) => {
      layoutSlice.state.menuOpen = false
      layoutSlice.state.wishListOpen = false
      layoutSlice.state.cartOpen = false
      layoutSlice.state.underlayOpen = false
      layoutSlice.state.samplesDialogOpen = false
      layoutSlice.state.searchOpen = false
    })
  }
}

export const getPageData = (state) => state.layout.page
export const getSiteData = (state) => state.layout.site
export const getDialogData = (state) => state.layout.dialog
export const getGTMId = (state) => getSiteData(state)?.gtmId
export const getKlaviyoId = (state) => getSiteData(state)?.klaviyoId
export const getCollectionData = (state) => getSiteData(state)?.collection
export const getShopAllCollection = (state) => getCollectionData(state)?.shopAllCategory
export const getSharedProductResources = (state) => getSiteData(state).product.resources
export const isMenuOpen = (state) => state.layout.menuOpen
export const isCartOpen = (state) => state.layout.cartOpen
export const hasCartBeenToggled = (state) => state.layout.cartToggled
export const isUnderlayOpen = (state) => state.layout.underlayOpen
export const hasUnderlayDialogBeenToggled = (state) => state.layout.underlayToggled
export const isWishlistOpen = (state) => state.layout.wishListOpen
export const hasWishlistBeenToggled = (state) => state.layout.wishListToggled
export const isShopFiltersDialogOpen = (state) => state.layout.shopFiltersDialogOpen
export const hasShopFiltersBeenToggled = (state) => state.layout.shopFiltersDialogToggled
export const isSamplesDialogOpen = (state) => state.layout.samplesDialogOpen
export const hasSamplesDialogBeenToggled = (state) => state.layout.samplesDialogToggled
export const getSamplesProduct = (state) => state.layout.samplesProduct
export const isSearchOpen = (state) => state.layout.searchOpen
export const hasSearchBeenToggled = (state) => state.layout.searchToggled
export const getTransition = (state) => state.layout.transition
export const isHomepageAnimatedIn = (state) => state.layout.isHomepageAnimatedIn
export const isMenuPreloaded = (state) => state.layout.preloadMenu
export const getSelectedCustomColor = (state) => state.layout.selectedCustomColor
export const isColorPickerDialogOpen = (state) => state.layout.colorPickerDialogOpen

export const isMenuLoaded = (state) => state.layout.isMenuLoaded
export const setMenuLoaded = (state) => { layoutSlice.state.isMenuLoaded = state }

export const getUnderlayDialogData = (state) => get(state.layout.site, ['product'])
export const getDesignConsultationForm = (state) => get(getSiteData(state), ['product', 'designConsultationForm'])
export const getSaleLabel = (state) => get(state.layout.site, ['product', 'saleLabel'])
export const getSelectColorLabel = (state) => get(state.layout.site, ['product', 'selectColorLabel'])
export const getSelectStockedColorLabel = (state) => get(state.layout.site, ['product', 'selectStockedColorLabel'])
export const getSelectCustomColorLabel = (state) => get(state.layout.site, ['product', 'selectCustomColorLabel'])
export const getSelectSizeLabel = (state) => get(state.layout.site, ['product', 'selectSizeLabel'])
export const getPreorderMessage = (state) => get(state.layout.site, ['product', 'productPreorderMessage'])
export const getLowStockMessage = (state) => get(state.layout.site, ['product', 'lowStockMessage'])
export const getOutOfStockMessage = (state) => get(state.layout.site, ['product', 'productOutOfStockMessage'])

export const isProductGalleryOpen = (state) => state.layout.productGalleryOpen
export const setProductGalleryOpen = (state) => { layoutSlice.state.productGalleryOpen = state }

export const isChatBotOpen = (state) => state.layout.chatOpen
export const setChatBotOpen = (state) => { layoutSlice.state.chatOpen = state }

export const isSalesPopupEnabled = (state) => state.layout.isSalesPopupEnabled
export const setIsSalesPopupEnabled = (state) => { layoutSlice.state.isSalesPopupEnabled = state }

export const galleryIndex = (state) => state.layout.galleryIndex
export const setGalleryIndex = (state) => { layoutSlice.state.galleryIndex = state }

export const toggleMenu = () => {
  layoutSlice.state.menuOpen = !layoutSlice.state.menuOpen
}
export const closeMenu = () => { layoutSlice.state.menuOpen = false }

export const openWishList = () => {
  layoutSlice.state.wishListOpen = true
  layoutSlice.state.wishListToggled = true
}
export const closeWishList = () => { layoutSlice.state.wishListOpen = false }

export const openShopFiltersDialog = () => {
  layoutSlice.state.shopFiltersDialogOpen = true
  layoutSlice.state.shopFiltersDialogToggled = true
}
export const closeShopFiltersDialog = () => { layoutSlice.state.shopFiltersDialogOpen = false }

export const openColorPickerDialog = () => {
  layoutSlice.state.colorPickerDialogOpen = true
}
export const closeColorPickerDialog = () => { layoutSlice.state.colorPickerDialogOpen = false }

export const setSelectedCustomColor = (color) => { layoutSlice.state.selectedCustomColor = color }

export const openSamplesDialog = () => {
  layoutSlice.state.samplesDialogOpen = true
  layoutSlice.state.samplesDialogToggled = true
}
export const setSamplesProduct = (productId) => { layoutSlice.state.samplesProduct = productId }
export const closeSamplesDialog = () => { layoutSlice.state.samplesDialogOpen = false }

export const toggleCart = () => {
  layoutSlice.state.cartOpen = !layoutSlice.state.cartOpen
  layoutSlice.state.cartToggled = true
}
export const closeCart = () => { layoutSlice.state.cartOpen = false }
export const openCart = () => {
  layoutSlice.state.cartOpen = true
  layoutSlice.state.cartToggled = true
}

export const toggleUnderlay = () => {
  layoutSlice.state.underlayOpen = !layoutSlice.state.underlayOpen
  layoutSlice.state.underlayToggled = true
}
export const closeUnderlay = () => { layoutSlice.state.underlayOpen = false }
export const openUnderlay = () => {
  layoutSlice.state.underlayOpen = true
  layoutSlice.state.underlayToggled = true
}

export const toggleSearch = () => {
  layoutSlice.state.searchOpen = !layoutSlice.state.searchOpen
  layoutSlice.state.searchToggled = true
}
export const closeSearch = () => { layoutSlice.state.searchOpen = false }

export const setSiteData = (site) => { layoutSlice.state.site = ref(site) }
export const setPageData = (page) => { layoutSlice.state.page = ref(page) }
export const setDialogData = (dialog) => { layoutSlice.state.dialog = dialog ? ref(dialog) : null }
export const setTransition = (type, path, options) => {
  layoutSlice.state.transition.type = type
  layoutSlice.state.transition.path = path
  layoutSlice.state.transition.options = options
}
export const clearTransition = () => { layoutSlice.state.transition = {} }

export const getCurrentBreakpoint = (state) => state.layout.breakpoint
export const setCurrentBreakpoint = (bp) => { layoutSlice.state.breakpoint = bp }

export const isCurrentBreakpointMobile = (state) => state.layout.isMobile

export const setPagedData = (pagedData) => { layoutSlice.state.page.pagedData = pagedData ? ref(pagedData) : null }

export const getJournals = (state) => get(state, ['layout', 'page', 'pagedData', 'journals', 'journals'])
export const getTotalJournals = (state) => get(state, ['layout', 'page', 'pagedData', 'journals', 'totalCount'])
export const getPostCategories = (state) => get(state, ['layout', 'page', 'pagedData', 'postCategories'])
export const getCurrentJournalPage = (state) => get(state, ['layout', 'page', 'pagedData', 'journals', 'page'])
export const getCurrentJournalCategory = (state) => get(state, ['layout', 'page', 'pagedData', 'journals', 'category'])

export const setHomepageAnimatedIn = () => { layoutSlice.state.isHomepageAnimatedIn = true }

export const preloadMenu = () => { layoutSlice.state.preloadMenu = true }

export const getTopBannerHeight = (state) => state.layout.topBannerHeight
export const setTopBannerHeight = (height) => { layoutSlice.state.topBannerHeight = height }

const addBreakpointListener = () => {
  const checkBreakpoint = () => {
    const bp = current()
    const prev = layoutSlice.state.breakpoint
    if (bp !== prev) {
      setCurrentBreakpoint(bp)
    }
  }
  if (isBrowser) {
    window.addEventListener('resize', checkBreakpoint, { passive: true })
    checkBreakpoint()
  }
}

addBreakpointListener()

export default layoutSlice
