import { createProxyState } from './stateHelpers'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { isBrowser } from '../lib/helpers'
import { fetchCart } from './cartApi'
import { subscribeKey } from 'valtio/utils'
import layoutSlice, { openCart } from './layoutSlice'
import queryString from 'query-string'
import defer from 'lodash/defer'

export const cartSlice = {
  name: 'cart',
  state: {},
  create: (initialState) => {
    cartSlice.state = createProxyState({
      cartLoading: true,
      cartError: false,
      donationVariant: 0,
      cart: {
        currency: {
          code: 'AUD'
        },
        cartAmount: 0,
        baseAmount: 0,
        discountAmount: 0,
        taxIncluded: false,
        lineItems: {},
        numberItems: 0,
        redirectUrls: {}
      }
    })

    if (isBrowser) {
      fetchCart()
    }

    return cartSlice.state
  },
  subscribe: () => {
    subscribeKey(layoutSlice.state, 'site', (site) => {
      if (isBrowser) {
        fetchCart()
      }
    })
  }
}

export const getCartLoading = (state) => get(state, ['cart', 'cartLoading'])
export const getCartError = (state) => get(state, ['cart', 'cartError'])
export const getAddToCartError = (state) => get(state, ['cart', 'addToCartError'])
export const getCartItems = (state) => get(state, ['cart', 'cart', 'lineItems', 'physical_items'])
export const getCartGiftCertificates = (state) => get(state, ['cart', 'cart', 'lineItems', 'gift_certificates'])
export const getCartState = (state) => get(state, ['cart'])

export const hasCartItems = (state) => !isEmpty(getCartItems(state)) || !isEmpty(getCartGiftCertificates(state))

export const getDonationVariant = (state) => get(state, ['cart', 'donationVariant'])
export const setDonationVariant = (variant) => { cartSlice.state.donationVariant = variant }

export const setCartError = (error) => { cartSlice.state.cartError = error }

const checkChartOpenFromQueryString = () => {
  if (isBrowser) {
    const qs = queryString.parse(window.location.search)
    if (qs.cartOpen !== undefined) {
      defer(openCart)
    }
  }
}

checkChartOpenFromQueryString()

export default cartSlice
