import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { getGTMId } from '../store/layoutSlice'
import useSnapshot from '../store/useSnapshot'
import * as gtag from '../lib/gtag'
import defer from 'lodash/defer'

export default function GoogleTagManager () {
  const snap = useSnapshot()
  const gtmId = getGTMId(snap)
  const router = useRouter()

  // TODO: Not sure about the gtmId in the gtag('config') and the gtag.pageview
  // Documentation says to use the GA_MEASUREMENT_ID
  useEffect(() => {
    // We defer so the title of the page gets set before we run this
    defer(() => gtag.pageview(gtmId, router.asPath, document.title))
  }, [gtmId, router.asPath]) // eslint-disable-line

  return (
    <>
      {/* <Script src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`} strategy='afterInteractive' /> */}
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');
        `}
      </Script>
    </>
  )
}
