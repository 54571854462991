import Script from 'next/script'
import { getSiteData } from '../store/layoutSlice'
import useSnapshot from '../store/useSnapshot'

export default function MetaPixel () {
  const snap = useSnapshot()
  const site = getSiteData(snap)
  const locale = site?.locale

  const auId = '239380036630708'
  const usId = '2504165032986368'

  const id = locale === 'en-AU' ? auId : usId

  return (
    <>
      <Script id='meta-pixel' strategy='afterInteractive'>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${id}');
          fbq('track', 'PageView');
        `}
      </Script>
    </>
  )
}
