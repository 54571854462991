import { useSnapshot as useValtioSnapshot } from 'valtio'
import { devtools } from 'valtio/utils'

import routeSlice from './routeSlice'
import layoutSlice from './layoutSlice'
import subscriptionSlice from './subscriptionSlice'
import preloadSlice from './preloadSlice'
import productsSlice from './productsSlice'
import productListingSlice from './productListing'
import sampleListingSlice from './sampleListing'
import wishListSlice from './wishListSlice'
import productCollectionSlice from './productCollectionSlice'
import shopFiltersSlice from './shopFiltersSlice'
import searchSlice from './searchSlice'
import recentSearchSlice from './recentSearchSlice'
import cartSlice from './cartSlice'
import pricesSlice from './pricesSlice'
import unitSystemSlice from './unitSystemSlice'
import salesPopupSlice from './salesPopup'
import enquiryPopupSlice from './enquiryPopup'
import { isBrowser } from '../lib/helpers'
import React from 'react'
import { createProxyState } from './stateHelpers'
import forEach from 'lodash/forEach'

export let state = null

function addSlices (slices, initialState) {
  forEach(slices, slice => {
    state[slice.name] = slice.create(initialState)
  })
  if (isBrowser) {
    forEach(slices, slice => {
      if (slice.derive) slice.derive()
      if (slice.subscribe) slice.subscribe()
    })
  }
}

export function createState (initialState) {
  // Only create the state if we are on the server or we have not created the state yet
  if (!state || !isBrowser) {
    state = {}
    addSlices([
      routeSlice,
      layoutSlice,
      subscriptionSlice,
      preloadSlice,
      productsSlice,
      productListingSlice,
      sampleListingSlice,
      wishListSlice,
      productCollectionSlice,
      shopFiltersSlice,
      searchSlice,
      unitSystemSlice,
      recentSearchSlice,
      cartSlice,
      pricesSlice,
      salesPopupSlice,
      enquiryPopupSlice
    ], initialState)
    state = createProxyState(state)
    if (isBrowser && process.env.NODE_ENV !== 'production') devtools(state, { name: 'Global State' })
  }
  return state
}

// A wrapper for useProxy
export default function useSnapshot () {
  const state = React.useContext(stateContext)
  if (isBrowser) {
    // This is not actually conditional. Be quiet, eslint :)
    // eslint-disable-next-line
    return useValtioSnapshot(state)
  }
  return state
}

export const stateContext = React.createContext(null)
export const StateProvider = stateContext.Provider
