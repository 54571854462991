import { isBrowser } from '../lib/helpers'
import { createProxyState } from './stateHelpers'
import remove from 'lodash/remove'
import delay from 'lodash/delay'
import routeSlice from './routeSlice'
import { openWishList } from './layoutSlice'
import { subscribe } from 'valtio'
import qs from 'querystringify'
import { localStore } from '../helpers/storageFactory'
import { trackAddToWishlist, trackRemoveFromWishlist } from '../lib/gtag'

const SESSION_KEY = 'armadillo-wishlist-v1'

export const wishListSlice = {
  name: 'wishList',
  state: {},
  create: (initialState) => {
    wishListSlice.state = createProxyState({
      productVariants: [],
      sharedProductVariants: [],
      shared: false
    })

    // We need to do this on the next tick so that is does not give a hydration issue
    delay(() => {
      if (isBrowser) {
        checkSharedRoute(window.location.pathname)
        const data = localStore.getItem(SESSION_KEY)
        if (data) {
          wishListSlice.state.productVariants = JSON.parse(data)
        }
      }
    })

    return wishListSlice.state
  },
  subscribe: () => {
    subscribe(routeSlice.state, (op) => {
      checkSharedRoute(op[0][2])
    })
  }
}

const checkSharedRoute = (pathname) => {
  const regEx = /^((\/us|)\/share\/wishlist)$/g
  if (isBrowser && pathname && regEx.test(pathname)) {
    const { items: itemsQs } = qs.parse(window.location.search)
    if (itemsQs) {
      const items = itemsQs.split(',')
      const variants = items
        .map(value => value.split('-'))
        .map(([productId, variantId]) => ({ productId: parseInt(productId), variantId: parseInt(variantId) }))

      wishListSlice.state.shared = true
      wishListSlice.state.sharedProductVariants = variants
      openWishList()
    }
  } else {
    // We are no longer looking at the shared wishlist
    if (wishListSlice.state.shared) wishListSlice.state.shared = false
  }
}

export const getWishListProducts = (state) => state.wishList.productVariants
export const getSharedWishListProducts = (state) => state.wishList.sharedProductVariants
export const isShowingSharedWishList = (state) => state.wishList.shared

export const clearWishList = () => {
  wishListSlice.state.productVariants = []
  localStore.setItem(SESSION_KEY, JSON.stringify(wishListSlice.state.productVariants))
}

export const addProductToWishList = (productId, variantId, productDatalayerObject) => {
  if (wishListSlice.state.productVariants.find(x => x.productId === productId && x.variantId === variantId)) {
    console.warn('A product with that variant is already in the wishlist')
    return
  }
  wishListSlice.state.productVariants.push({ productId, variantId })
  if (productDatalayerObject) {
    trackAddToWishlist([productDatalayerObject])
  }
  localStore.setItem(SESSION_KEY, JSON.stringify(wishListSlice.state.productVariants))
  openWishList()
}

export const removeProductFromWishList = (productId, variantId, productDatalayerObject, tileIndex) => {
  remove(wishListSlice.state.productVariants, x => x.productId === productId && x.variantId === variantId)
  if (productDatalayerObject) {
    trackRemoveFromWishlist([productDatalayerObject], tileIndex)
  }
  localStore.setItem(SESSION_KEY, JSON.stringify(wishListSlice.state.productVariants))
}

export default wishListSlice
