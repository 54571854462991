export function getSizeVariant (variantOptions) {
  return variantOptions?.find(val => val.option_display_name.indexOf('Size') >= 0)
}

export function isColorVariantName (name) {
  return name?.indexOf('Color') >= 0 || name?.indexOf('Colour') >= 0
}

export function isColorVariant (variant) {
  return isColorVariantName(variant.option_display_name)
}

export function isCustomColor (colorOption) {
  return !!colorOption?.priceMetre || !!colorOption?.price?.metre
}

export function getColorVariant (variantOptions) {
  return variantOptions?.find(isColorVariant)
}
