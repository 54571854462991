import React, { useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { theme } from '../styles/theme'
import initLazySizes from '../initLazySizes'
import get from 'lodash/get'
import { state, createState, StateProvider } from '../store/useSnapshot'
import { setRoutePath } from '../store/routeSlice'
import usePageThemeColorTransition from '../hooks/usePageThemeColorTransition'
import { getTransition } from '../store/layoutSlice'
import { setup } from 'goober'
import cssPrefix from '../helpers/cssPrefix'
import GoogleTagManager from '../components/GoogleTagManager'
import Klaviyo from '../components/Klaviyo'
import MetaPixel from '../components/MetaPixel'
import NProgress from 'nprogress'
import '../styles/nprogress.css'
import afterFrame from '../helpers/afterFrame'
import Insights from '../components/Insights'

setup(React.createElement, cssPrefix)

initLazySizes()

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => afterFrame(() => { NProgress.start() }))
Router.events.on('routeChangeComplete', () => afterFrame(() => { NProgress.done() }))
Router.events.on('routeChangeError', () => afterFrame(() => { NProgress.done() }))

const getTheme = (pageProps) => {
  const color = get(pageProps, 'data.page.color.title', 'default')
  return get(theme, ['colors', 'pageTheme', color])
}

const App = ({ Component, pageProps }) => {
  const router = useRouter()
  const [pageTheme, setPageTheme] = useState(getTheme(pageProps))

  useEffect(() => {
    // Only change the global background color when the page is not a dialog. The <Dialog /> component
    // manages its own background
    if (get(pageProps, 'data.page.layout') !== 'dialog') {
      setPageTheme(getTheme(pageProps))
    }
  }, [pageProps])

  // We ues the transition state transiently, because we do not want to rerender when this changes.
  // When the page changes we use the transition state then to determine how we transition
  const { type: transitionType, path: transitionPath } = getTransition(state) || {}
  const isTransitioningFromMenu = (transitionType === 'menu' || transitionType === 'tabs') && transitionPath === router.asPath
  // When we transition from the menu we do not need to animate the background colors as we already have this in the menu item
  usePageThemeColorTransition(pageTheme, null, isTransitioningFromMenu ? 0 : 0.5)

  useEffect(() => {
    setRoutePath(router.asPath)
    const handleRouteChange = (path) => {
      setRoutePath(path)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events]) // eslint-disable-line

  return (
    <>
      <Component {...pageProps} />
    </>
  )
}

const AppWrapper = (props) => {
  const { pageProps } = props
  const router = useRouter()
  if (pageProps.statusCode === 405) return null
  const state = createState({ pageData: pageProps.data, path: router.asPath })
  return (
    <StateProvider value={state}>
      <GoogleTagManager key='google-tag-manager' />
      <Klaviyo key='klaviyo' />
      <MetaPixel key='meta-pixel' />
      <App {...props} />
      <Insights />
    </StateProvider>
  )
}

export async function getInitialProps () {
}

export default AppWrapper
