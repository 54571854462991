import * as breakpoints from './breakpoints'
import * as fonts from './fonts'
import color from 'color'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  background: 'var(--background)',
  transparentBackground: 'var(--transparentBackground)',
  text: 'var(--foreground)',
  border: 'var(--border)',
  button: {
    backgroundHover: '#566160',
    foregroundHover: '#fff'
  },
  error: '#C25A1D',
  success: '#288012',
  imageBackground: '#F0EEE9',
  pageTheme: {
    default: { // Milk
      background: '#F9FCF8',
      transparentBackground: color('#F9FCF8').alpha(0).string(),
      foreground: '#000000',
      border: '#B9BBB8'
    },
    dove: {
      background: '#DEDCD1',
      transparentBackground: color('#DEDCD1').alpha(0).string(),
      foreground: '#000000',
      border: '#B9BBB8'
    },
    ecru: {
      background: '#EDEBDB',
      transparentBackground: color('#EDEBDB').alpha(0).string(),
      foreground: '#000000',
      border: '#B9BBB8'
    },
    sandstone: {
      background: '#DECAA7',
      transparentBackground: color('#DECAA7').alpha(0).string(),
      foreground: '#000000',
      border: '#000000'
    },
    desert: {
      background: '#C6A390',
      transparentBackground: color('#C6A390').alpha(0).string(),
      foreground: '#000000',
      border: '#000000'
    },
    mushroom: {
      background: '#9A9B94',
      transparentBackground: color('#9A9B94').alpha(0).string(),
      foreground: '#000000',
      border: '#B9BBB8'
    },
    moss: {
      background: '#7E8179',
      transparentBackground: color('#7E8179').alpha(0).string(),
      foreground: '#000000',
      border: '#B9BBB8'
    },
    eucalyptus: {
      background: '#566160',
      transparentBackground: color('#566160').alpha(0).string(),
      foreground: '#000000',
      border: '#B9BBB8'
    },
    gunsmoke: {
      background: '#83867E',
      transparentBackground: color('#83867E').alpha(0).string(),
      foreground: '#000000',
      border: '#B9BBB8'
    }
  }
}

const gutter = {
  sm: 24,
  md: 24
}

export const grid = {
  sm: {
    gutter: 16,
    columns: 8
  },
  md: {
    gutter: 24,
    columns: 12
  }
}

export const getGridGutter = (breakpoint = 'sm') => gutter[breakpoint]

export const gridStyle = (columns = grid.md.columns) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.sm.columns}, minmax(0px, 1fr))`,
  gap: `${grid.sm.gutter}px`,
  [breakpoints.up('md')]: {
    gap: `${grid.md.gutter}px`,
    gridTemplateColumns: `repeat(${columns}, minmax(0px, 1fr))`
  }
})

export const span = (cols, breakpoint = 'sm', padding = 0, wrap = true, leftOffset = 0) => {
  if (!grid[breakpoint]) return null
  const gridGutter = grid[breakpoint].gutter
  const gridColumns = grid[breakpoint].columns
  const calc = `(((100vw - ${gridGutter * (gridColumns - 1)}px - ${padding}px) / ${gridColumns}) * ${cols}) + ${gridGutter * (Math.abs(cols) - 1)}px + ${leftOffset}px`
  return wrap ? `calc(${calc})` : calc
}

const spacing = (count = 1) => count * 8
const spacingPx = (count = 1) => `${count * 8}px`

const eases = {
  linear: 'cubic-bezier( 0.250,  0.250,  0.750,  0.750 )',
  ease: 'cubic-bezier( 0.250,  0.100,  0.250,  1.000 )',
  easeIn: 'cubic-bezier( 0.420,  0.000,  1.000,  1.000 )',
  easeOut: 'cubic-bezier( 0.000,  0.000,  0.580,  1.000 )',
  easeInOut: 'cubic-bezier( 0.420,  0.000,  0.580,  1.000 )',

  easeInQuad: 'cubic-bezier( 0.550,  0.085,  0.680,  0.530 )',
  easeInCubic: 'cubic-bezier( 0.550,  0.055,  0.675,  0.190 )',
  easeInQuart: 'cubic-bezier( 0.895,  0.030,  0.685,  0.220 )',
  easeInQuint: 'cubic-bezier( 0.755,  0.050,  0.855,  0.060 )',
  easeInSine: 'cubic-bezier( 0.470,  0.000,  0.745,  0.715 )',
  easeInExpo: 'cubic-bezier( 0.950,  0.050,  0.795,  0.035 )',
  easeInCirc: 'cubic-bezier( 0.600,  0.040,  0.980,  0.335 )',
  easeInBack: 'cubic-bezier( 0.600, -0.280,  0.735,  0.045 )',

  easeOutQuad: 'cubic-bezier( 0.250,  0.460,  0.450,  0.940 )',
  easeOutCubic: 'cubic-bezier( 0.215,  0.610,  0.355,  1.000 )',
  easeOutQuart: 'cubic-bezier( 0.165,  0.840,  0.440,  1.000 )',
  easeOutQuint: 'cubic-bezier( 0.230,  1.000,  0.320,  1.000 )',
  easeOutSine: 'cubic-bezier( 0.390,  0.575,  0.565,  1.000 )',
  easeOutExpo: 'cubic-bezier( 0.190,  1.000,  0.220,  1.000 )',
  easeOutcirc: 'cubic-bezier( 0.075,  0.820,  0.165,  1.000 )',
  easeOutBack: 'cubic-bezier( 0.175,  0.885,  0.320,  1.275 )',

  easeInOutQuad: 'cubic-bezier( 0.455,  0.030,  0.515,  0.955 )',
  easeInOutCubic: 'cubic-bezier( 0.645,  0.045,  0.355,  1.000 )',
  easeInOutQuart: 'cubic-bezier( 0.770,  0.000,  0.175,  1.000 )',
  easeInOutQuint: 'cubic-bezier( 0.860,  0.000,  0.070,  1.000 )',
  easeInOutSine: 'cubic-bezier( 0.445,  0.050,  0.550,  0.950 )',
  easeInOutExpo: 'cubic-bezier( 1.000,  0.000,  0.000,  1.000 )',
  easeInOutCirc: 'cubic-bezier( 0.785,  0.135,  0.150,  0.860 )',
  easeInOutBack: 'cubic-bezier( 0.680, -0.550,  0.265,  1.550 )'
}

export const theme = {
  zIndex: {
    headerGradient: 1,
    image: 2,
    dropDown: 3,
    dropDownButton: 4,
    imageContent: 5,
    headerBackground: 6,
    stickyNav: 900,
    cartMobile: 950,
    header: 1000,
    dialog: 1100,
    scrollBar: 1200,
    menu: 1300,
    cart: 1400,
    underlayDialog: 1401,
    gallery: 1500,
    galleryFullScreen: 1600,
    pageTransitionCurtain: 1650,
    chatButton: 1675,
    cursor: 1700
  },
  section: {
    marginBottom: {
      sm: spacing(8),
      md: spacing(10),
      xl: spacing(20)
    }
  },
  headerSize: {
    sm: 88,
    md: 112
  },
  fonts,
  breakpoints,
  colors,
  spacing,
  spacingPx,
  gutter,
  gridStyle: gridStyle(),
  dialogGridStyle: gridStyle(11),
  eases
}
