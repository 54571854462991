import { subscribeKey } from 'valtio/utils'
import { isBrowser } from '../lib/helpers'
import routeSlice from './routeSlice'
import { createProxyState } from './stateHelpers'
import layoutSlice from './layoutSlice'

const checkPopup = () => {
  if (isBrowser) {
    setOpen(true)
  }
}

export const enquiryPopupSlice = {
  name: 'enquiryPopup',
  state: {},
  create: ({ pageData }) => {
    enquiryPopupSlice.state = createProxyState({
      open: false
    })
    return enquiryPopupSlice.state
  },
  subscribe: () => {
    subscribeKey(routeSlice.state, 'pathname', (value) => {
      enquiryPopupSlice.state.open = false
    })
    subscribeKey(layoutSlice.state, 'site', (value) => {
      checkPopup()
    })
  }
}

export const isOpen = (state) => state.enquiryPopup.open
export const setOpen = (value) => { enquiryPopupSlice.state.open = value }

export const openEnquiryPopup = (state) => {
  enquiryPopupSlice.state.open = true }

export const dismiss = () => {
  enquiryPopupSlice.state.open = false
}

export default enquiryPopupSlice
