import { createProxyState } from './stateHelpers'

export const routeSlice = {
  name: 'route',
  state: {},
  create: ({ path }) => {
    routeSlice.state = createProxyState({
      pathname: path,
      query: null
    })
    return routeSlice.state
  }
}

export const setRoutePath = (pathname) => {
  routeSlice.state.pathname = pathname
}

export default routeSlice
