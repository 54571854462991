import { createProxyState } from './stateHelpers'

export const productCollectionSlice = {
  name: 'productCollection',
  state: {},
  create: ({ pageData }) => {
    productCollectionSlice.state = createProxyState({
      index: -1
    })
    return productCollectionSlice.state
  }
}

export const getIndex = (state) => state.productCollection.index

export const setIndex = (index) => { productCollectionSlice.state.index = index }

export default productCollectionSlice
