import { createProxyState } from './stateHelpers'

export const LAYOUT = { styled: 'styled', uniform: 'uniform' }

export const layouts = [
  {
    mode: LAYOUT.uniform,
    icon: (
      <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.5' y='0.625' width='4' height='4' stroke='currentColor' />
        <rect x='0.5' y='7.375' width='4' height='4' stroke='currentColor' />
        <rect x='7.25' y='0.625' width='4' height='4' stroke='currentColor' />
        <rect x='7.25' y='7.375' width='4' height='4' stroke='currentColor' />
      </svg>
    )
  },
  {
    mode: LAYOUT.styled,
    icon: (
      <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='1.25' y='0.5' width='11' height='11' stroke='currentColor' />
      </svg>
    )
  }
]

const getInitialLayout = (page) => {
  let layout = LAYOUT.styled
  const productListingSlice = page?.slices?.find(x => x._type === 'product_listing')
  if (productListingSlice) {
    layout = productListingSlice.useCompactGrid ? LAYOUT.uniform : LAYOUT.styled
  }
  return layout
}

export const productListingSlice = {
  name: 'productListing',
  state: {},
  create: ({ pageData }) => {
    productListingSlice.state = createProxyState({
      layout: getInitialLayout(pageData.page),
      index: 0,
      filter: {}
    })
    return productListingSlice.state
  }
}

export const getLimit = (state) => state.productListing.layout === LAYOUT.styled ? 14 : 20

export const getLayout = (state) => state.productListing.layout
export const getIndex = (state) => state.productListing.index
export const getFilter = (state) => state.productListing.filter

export const setLayout = (layout) => { productListingSlice.state.layout = layout }

export const setPageIndex = (index) => {
  productListingSlice.state.index = index
}

export const loadNextPage = (total, currentLength) => {
  if (total > currentLength) {
    productListingSlice.state.index += 1
  }
}

export default productListingSlice
