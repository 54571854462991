import { subscribeKey } from 'valtio/utils'
import { isBrowser } from '../lib/helpers'
import layoutSlice from './layoutSlice'
import { createProxyState } from './stateHelpers'
import get from 'lodash/get'
import first from 'lodash/first'
import { loadPrices } from './pricesSlice'

const getUnderlayDonationAndProducts = (products, underlayProductId, donationProductId) => {
  return {
    products: products,
    underlay: underlayProductId ? products?.filter(x => x._id === underlayProductId) : undefined,
    donation: donationProductId ? products?.filter(x => x._id === donationProductId) : undefined
  }
}

export const productsSlice = {
  name: 'products',
  state: {},
  create: ({ pageData }) => {
    productsSlice.state = createProxyState({
      products: [],
      underlay: [],
      donation: [],
      country: null,
      total: 0,
      priceRange: null,
      loaded: false
    })

    if (isBrowser) {
      fetchShopProducts(layoutSlice.state.site)
    }

    return productsSlice.state
  },
  subscribe: () => {
    subscribeKey(layoutSlice.state, 'site', (site) => {
      // This will fetch all the products then the site changes
      if (isBrowser && !productsSlice.state.loaded && !productsSlice.state.loading) {
        productsSlice.state.products = null
        productsSlice.state.total = 0
        fetchShopProducts(site)
      }
    })
  }
}

export const isLoading = (state) => state.products.loading
export const getProduct = (state, productId) => {
  if (state.products.products && state.products.products.length) {
    return state.products.products.find(product => product._id === productId)
  }
  return null
}
export const getProductByBigCommerceId = (state, productId) => {
  if (state.products.products && state.products.products.length) {
    return state.products.products.find(product => product.bigCommerceId === productId)
  }
  return null
}
export const getProducts = (state) => state.products.products
export const getProductsLoaded = (state) => state.products.loaded
export const getUnderlay = (state) => first(state.products.underlay)
export const getDonationProduct = (state) => first(state.products.donation)
export const getMaxPrice = (state) => state.products.priceRange.max
export const getMinPrice = (state) => state.products.priceRange.min
export const getCountry = (state) => state.products.country

export const fetchShopProducts = async (site) => {
  const underlayProductId = get(site, ['product', 'underlayProduct', '_id'])
  const donationProductId = get(site, ['product', 'donationProduct', '_id'])

  productsSlice.state.loading = true
  try {
    const response = await window.fetch(`/api/products?site=${site._id}`)
    if (response.ok) {
      const results = await response.json()
      const { products, underlay, donation } = getUnderlayDonationAndProducts(results.products, underlayProductId, donationProductId)
      productsSlice.state.products = products
      productsSlice.state.underlay = underlay
      productsSlice.state.donation = donation
      productsSlice.state.total = results.total - (underlay?.length || 0)
      productsSlice.state.loaded = true
      productsSlice.state.country = results.country

      loadPrices(results.prices)
    }
  } catch (error) {
    productsSlice.state.error = error
  } finally {
    productsSlice.state.loading = false
  }
}

export default productsSlice
