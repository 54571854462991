import { subscribeKey } from 'valtio/utils'
import routeSlice from './routeSlice'
import { createProxyState } from './stateHelpers'

export const SORT_RECENT = 'SORT_RECENT'
export const SORT_PRICE = 'SORT_PRICE'
export const SORT_NAME = 'SORT_NAME'

export const sortModes = [
  {
    label: 'Name',
    value: SORT_NAME
  },
  {
    label: 'Recent',
    value: SORT_RECENT
  },
  {
    label: 'Price',
    value: SORT_PRICE
  }
]

const initialFiltersState = {
  room: [],
  roomStyle: [],
  colour: [],
  size: [],
  price: {
    min: null,
    max: null
  },
  collection: [],
  suitability: [],
  fibre: [],
  inStockOnly: false
}

export const shopFiltersSlice = {
  name: 'shopFilters',
  state: {},
  create: (initialState) => {
    shopFiltersSlice.state = createProxyState({
      sort: SORT_NAME,
      filters: initialFiltersState,
      category: null,
      count: 0
    })
    return shopFiltersSlice.state
  },
  subscribe: () => {
    subscribeKey(routeSlice.state, 'pathname', (value) => {
      shopFiltersSlice.state.filters = initialFiltersState
    })
  }
}

export const getShopFilters = (state) => state.shopFilters.filters
export const getShopFilterCategory = (state) => state.shopFilters.category
export const getRoomFilters = (state) => getShopFilters(state).room
export const getRoomStyleFilters = (state) => getShopFilters(state).roomStyle
export const getColourFilters = (state) => getShopFilters(state).colour
export const getInStockOnlyFilter = (state) => getShopFilters(state).inStockOnly
export const getSizeFilters = (state) => getShopFilters(state).size
export const getMinPriceFilter = (state) => getShopFilters(state).price.min
export const getMaxPriceFilter = (state) => getShopFilters(state).price.max
export const getCollectionFilters = (state) => getShopFilters(state).collection
export const getSuitabilityFilters = (state) => getShopFilters(state).suitability
export const getFibreFilters = (state) => getShopFilters(state).fibre
export const getShopFiltersResultCount = (state) => state.shopFilters.count
export const getSortMode = (state) => state.shopFilters.sort

export const setShopFilters = (filters) => { shopFiltersSlice.state.filters = filters }
export const setMinPriceFilter = (val) => { shopFiltersSlice.state.filters.price.min = val }
export const setMaxPriceFilter = (val) => { shopFiltersSlice.state.filters.price.max = val }
export const setShopFiltersResultCount = (count) => { shopFiltersSlice.state.count = count }
export const setSortMode = (sortMode) => { shopFiltersSlice.state.sort = sortMode }
export const setShopFiltersCategory = (category) => { shopFiltersSlice.state.category = category }

export const clearShopFilters = () => {
  shopFiltersSlice.state.filters = initialFiltersState
}

export default shopFiltersSlice
